import Icon from "components/icon/Icon";
import { ChangeEvent, FunctionComponent } from "react";
import './StyledSelect.scss';

interface Props {
  options: string;
  value: number;
  handleChange: (e: ChangeEvent<HTMLSelectElement>) => void;
}

const StyledSelect: FunctionComponent<Props> = ({ options, value, handleChange }) => {
  return (
    <div>
      <div className="styled-select">
        <div className="styled-select__value">
          {value}
        </div>
        <div className="styled-select__arrow">
          <Icon name="arrowDown" size="xsmall" />
        </div>
        <select className="styled-select__form-element" value={value} onChange={handleChange} dangerouslySetInnerHTML={{ __html: options }} />
      </div>
    </div>
  );
};

export default StyledSelect;
